import request from '@/utils/request'

// 分类同步接口 牵牛花同步到中间表，中间表同步到丽日购，丽日购编辑更新到中间表，查询展示接口

// 分类树形结构tree  查询展示接口 --------------------6.6
export function treeClassifyMappingByStoreId(params) {
    return request({
        url: '/small-inteface/goods-service/techfuser/treeClassifyMappingByStoreId/'+params.storeId,
        method: 'get',
        // params
    })
}
// 更新分类映射信息 ---------------编辑更新接口 6.4
export function updateClassifyMapping(params) {
    return request({
        url: '/small-inteface/goods-service/techfuser/updateClassifyMapping',
        method: 'post',
        params
    })
}

// 同步映射分类结构到丽日购 ----------同步牵牛花到中间表 6.3
export function syncClassifyMapping(data) {
    return request({
        url: '/small-inteface/goods-service/techfuser/syncClassifyMapping/'+data.storeId,
        method: 'get',
        // data
    })
}
// 同步映射分类结构到丽日购 ----------同步中间表信息到丽日购    6.7
export function syncClassify(data) {
    return request({
        url: '/small-inteface/goods-service/techfuser/syncClassify',
        method: 'get',
        data
    })
}


// --------------------------------------门店--------------------------------

// 查询展示 6.2
export function pageStoreMapping(params) {
    return request({
        url: '/small-inteface/goods-service/techfuser/pageStoreMapping',
        method: 'post',
        params
    })
}
// 同步更新门店到映射表,修改丽日购门店名称和同步状态用 6.1
export function updateStoreMapping(data) {
    return request({
        url: '/small-inteface/goods-service/techfuser/updateStoreMapping',
        method: 'post',
        data
    })
}
//同步映射表 6.0
export function syncStoreMapping(data) {
    return request({
        url: '/small-inteface/goods-service/techfuser/syncStoreMapping',
        method: 'post',
        data
    })
}