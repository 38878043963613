<template>
<!-- 分类信息同步 -->
  <div class="body" >
      <div class="header">
          <el-form ref="form" :model="form" label-width="80px">
              <!-- <el-form-item label="供应商别名">
                  <el-input v-model="form.supplyName"></el-input>
              </el-form-item>
              <el-form-item label="供应商/门店名称">
                  <el-input v-model="form.supplyStoreName"></el-input>
              </el-form-item> -->
              <el-form-item label="门店" class="itemBox">
                  <el-select v-model="storeId" placeholder="请选择">
                    <el-option
                    v-for="item in storeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
              </el-form-item>
              <el-button type="primary" @click="getMessage()" class="itemBox">查询</el-button>
              <el-tooltip class="item" effect="dark" content="将牵牛花数据同步到本地数据库" placement="top">
                  <el-button type="primary" @click="equalMesgFromQnh()" class="itemBox">一键获取牵牛花分类</el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="请先执行左侧操作后再执行此操作" placement="top">
                  <el-button type="primary" @click="equalMesg()" class="itemBox">一键同步到丽日购</el-button>
              </el-tooltip>
          </el-form>
          
      </div>
      <div class="tableBox">
          <!-- <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 50, 100, 200]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalSize">
          </el-pagination> -->
          <el-table
            :data="tableData"
            style="width: 100%;margin-bottom: 20px;"
            row-key="id"
            border
            default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            v-loading="loading">
            <el-table-column prop="classifyName" label="门店名"  width="160" align="center">
                <template slot-scope="scope">
                    <span>{{getStoreName(scope.row.storeId)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="supplyName" label="供应商别名"  align="center" width="70"></el-table-column>
            <el-table-column prop="supplyClassifyId" label="供应商分类编码"  align="center"></el-table-column>
            <el-table-column prop="supplyClassifyName" label="供应商分类名称"  align="center"></el-table-column>
            <!-- <el-table-column prop="parentSupplyClassifyId" label="供应商上级分类编码"  align="center"></el-table-column> -->
            <el-table-column prop="parentSupplyClassifyName" label="供应商上级分类名称"  align="center"></el-table-column>
            <!-- <el-table-column prop="supplyClassifyRowno" label="供应商排序号"  align="center"></el-table-column> -->
            <el-table-column prop="supplyClassifyIsused" label="供应商是否启用"  align="center" width="160">
                <template slot-scope="scope">
                    <el-switch 
                      v-model="scope.row.supplyClassifyIsused"
                      active-text="启用" 
                      inactive-text="禁用" 
                      active-color='#13ce66' 
                      inactive-color="#ff4949" 
                      disabled>
                      </el-switch>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="classifyId" label="丽日购分类id"  align="center"></el-table-column> -->
            <el-table-column prop="classifyName" label="丽日购分类名称"  align="center" width="260">
                <template slot-scope="scope">
                    <div v-if="!scope.row.isEdit">
                        <span  class="classifyName">{{scope.row.classifyName}}</span>
                        <el-button type="text" @click="editClassfiName(scope.row)" icon="el-icon-edit" class="pdlr-24"></el-button>
                    </div>
                    <div v-if="scope.row.isEdit" style="height:36px">
                        <el-input  v-model="scope.row.classifyName" size="mini" placeholder="请输入新的丽日购分类名" class="editItem inputwidth"></el-input>
                        <div class="editItem">
                            <el-button type="primary" size="mini" @click="editSure(scope.row,1)" class=" mglr-24">确定</el-button>
                            <el-button type="primary" size="mini" @click="editSure(scope.row,0)" class=" mglr-24">取消</el-button>
                        </div>
                        
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="parentClassifyId" label="丽日购上级分类id"  align="center"></el-table-column> -->
            
            <el-table-column prop="parentClassifyName" label="丽日购上级分类名称"  align="center"></el-table-column>
            <el-table-column prop="sync" label="同步标识"  align="center" width="160">
                <template slot-scope="scope">
                    <el-switch
                     v-model="scope.row.sync" 
                     active-text="同步" inactive-text="未同步" 
                     active-color='#13ce66' 
                     inactive-color="#ff4949"
                     :active-value="1"
                     :inactive-value="0"
                     @change="editSure(scope.row,3)"
                     ></el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="updateTime" label="更新时间"  width="200" align="center"></el-table-column>
          </el-table>
      </div>
  </div>
</template>
<script>
import axios from "axios";
import {treeClassifyMappingByStoreId,syncClassify,syncClassifyMapping,updateClassifyMapping} from '@/api/config_center/equalGoodsMsg';
export default {
    data(){
        return {
            form:{
               supplyName:"",
               supplyStoreName:"",
            },
            tableData:[],
            pageNum:1,
            pageSize:10,
            totalSize:100,
            storeId:1,
            storeList:[
                {id:1,name:'丽日江北店'},
                {id:2,name:'丽日湖畔店'},
                {id:3,name:'香榭里大街店'},
                {id:4,name:'丽日生活汇'},
                {id:5,name:'丽日河源中心城店'},
            ],
            editName:'',
            baseURL: process.env.VUE_APP_BASE_API,
            loading:false,
        }
    },
    mounted() {
        this.getMessage()
    },
    methods:{
        // 页容变更
        handleSizeChange(e){
            console.log(e)
            this.pageSize = e
        },
        // 页码变更
        handleCurrentChange(e){
            console.log(e)
            this.pageNum = e
        },
        // 查询分类树形结构展示
        getMessage(){
            this.loading = true
            let param = {
                // pageNum:this.pageNum,
                // pageSize:this.pageSize,
                // supplyName:this.form.supplyName,
                // supplyStoreName:this.form.pageNsupplyStoreNameum,
                storeId:this.storeId
            }
            treeClassifyMappingByStoreId(param).then(res=>{
                console.log(res)
                let tableData = res.data.body
                tableData.forEach(item=>{
                    item.isEdit = false
                    if(item.children.length>0){
                        item.children.forEach(e=>{
                            e.isEdit = false
                        })
                    }
                })
                console.log(tableData)
                this.tableData = tableData
            }).catch(err=>{
                console.log(err)
            }).finally(()=>{
                this.loading = false
                console.log('函数执行结束')
            })
        },
        // 中间表一键同步到丽日购
        equalMesg(){
            syncClassifyMapping().then(res=>{
                console.log(res)
            }).catch(err=>{
                console.log(err)
            }).finally(()=>{
                console.log('函数执行结束')
            })
        },
        // 牵牛花同步到中间表
        equalMesgFromQnh(){
            syncClassify().then(res=>{
                console.log(res)
            }).catch(err=>{
                console.log(err)
            }).finally(()=>{
                console.log('函数执行结束')
            })
        },
        // 门店id转门店名
        getStoreName(e){
          let name = ''
          this.storeList.forEach(item=>{
              if(item.id == e){
                  name = item.name
              }
          })
          return name
        },
        // 时间戳变更，暂时用不上
        getUpdate(e){
            let yyyy = e.getFullYear()
            let MM = e.getMonth()+1
            let dd = e.getDate()
            let mm = e.getMinutes()
            let hh = e.getHours()
            let ss = e.getSeconds()
            let time = yyyy+'年'+MM+'月'+dd+'日'+" "+mm+':'+hh+':'+ss
            console.log(time)
            return time
        },
        // 更改丽日购分类名
        editClassfiName(e){
            console.log(e)
            e.isEdit = true
            this.editName = e.classifyName
            // this.editRow = e
        },
        // 编辑确认提交
        editSure(e,type){
            this.loading = true
            if((type == 1 && e.classifyName != this.editName) || type == 3){
                let param = {
                    id:e.id,
                    classifyId:e.classifyId,
                    classifyName:e.classifyName,
                    parentClassifyId:e.parentClassifyId,
                    parentClassifyName:e.parentClassifyName,
                    sync:e.sync,
                }
                // updateClassifyMapping(param).then(res=>{
                axios.post(this.baseURL+'/small-inteface/goods-service/techfuser/updateClassifyMapping',param).then(res=>{
                    // console.log(res)
                    this.$message({
                        message: '修改成功！',
                        type: 'success'
                    });
                }).catch(err=>{
                    // console.log(res)
                    this.$message({
                        message: '修改失败！',
                        type: 'error'
                    });
                    console.log(err)
                }).finally(()=>{
                    this.loading = false
                    // console.log('函数执行结束')
                    this.getMessage()
                })
            }
            e.isEdit = false
        }
        

    },
}
</script>
<style lang="scss" scoped>
.itemBox{
    // width: 500px;
    float: left;
    margin:  12px 12px;
}
.pdlr-24{
    padding: 0 12px;
}
.mglr-24{
    padding:0 12px,
}
.classifyName{
    width: 80px;
    display: inline-block;
    text-align: right;
}
.editItem{
    float: left;
}
.inputwidth{
    width: 120px;
    height: 36px;
}
/deep/.el-table td, .el-table th{
    height: 36px;
    line-height: 36px;
    padding: 0;
}
/deep/.el-table .cell{
    min-height: 36px;
    line-height: 36px;
}
</style>